<template>
  <swiper 
    :modules="modules"
    :slides-per-view="1.1"
    :loop="true"
    :space-between="5"
    :autoplay="{delay: 2000}"
    :pagination="{ clickable: true}"
    :scrollbar="{ draggable: true}"
    
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide><img src="/assets/thumb(2).jpg" alt=""></swiper-slide>
    <swiper-slide><img src="/assets/thumb(1).jpg" alt=""></swiper-slide>
    <swiper-slide><img src="/assets/thumb(2).jpg" alt=""></swiper-slide>
  </swiper>
</template>
  
  <script>
    import { defineComponent } from 'vue';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Autoplay, Navigation, Pagination, Scrollbar, A11y, EffectFade} from 'swiper/modules';
  
    import 'swiper/css';
    import 'swiper/css/autoplay';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import 'swiper/css/scrollbar';
    import 'swiper/css/effect-fade';
  
    
  
    export default defineComponent({
      components: {
        Swiper,
        SwiperSlide,
      },
      setup() {
        const onSwiper = (swiper) => {
          //console.log(swiper);
        };
        const onSlideChange = () => {
          //console.log('slide change');
        };
        return {
          onSwiper,
          onSlideChange,
          modules: [Autoplay, Navigation, Pagination, Scrollbar, A11y, EffectFade],
        };
      }
    });
  </script>

<style scoped>
  .swiper {
    width: 100%;
  }
</style>