<script lang="js">
import { defineComponent, ref } from "vue";
import { musicalNotes } from 'ionicons/icons'
import NavBarSection from "@/components/NavBarSection.vue";

import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonCardHeader,
  IonCardTitle,
  IonImg,
  IonSkeletonText,
  IonSelectOption,
  IonSelect,
  IonItem,
} from "@ionic/vue";
export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonRow,
    IonCardHeader,
    IonCardTitle,
    NavBarSection,
    IonImg,
    IonSkeletonText,
    IonSelectOption,
    IonSelect,
    IonItem,
  },
  data() {
    return {
      items1: [],
      items2: [],
      thumbYoutubeBaseUrl: 'https://img.youtube.com/vi/',
      thumbYoutubeJpg: '/mqdefault.jpg',
      linkCulto: '',
      loading: false,
      selectedMonth: null,
      selectedYear: null,
      currentYear: null
    }
  },
  computed: {
    yearOptions() {
      const currentYear = new Date().getFullYear();
      const startYear = 2018;
      const years = [];
      for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
      }
      return years;
    }
  },
  mounted() {
    //this.selectedMonth = String(new Date().getMonth()+1); // A ideia é ao carregar a página trazer os ultimos 4 vídeos disponíveis independentemente do mês. Por isso esse código está desabilitado
    this.selectedYear = new Date().getFullYear();
    this.loadEvents();
  },
  methods: {
    async loadEvents() {
      this.loading = false

      const params = new URLSearchParams()
      if (this.selectedMonth != null && this.selectedYear != null) {
        params.append('dataInicial', this.selectedYear + '-' + this.selectedMonth)
        params.append('dataFinal', this.selectedYear + '-' + this.selectedMonth)
      }
      const url = new URL(this.$apiV1 + '/cultos/')
      url.search = params
      await fetch(url)
        .then(Response => Response.json())
        .then(async data => {
          this.items1 = data
          //this.items1 = data.slice(0, 1) // separa o primeiro do array
          //this.items2 = data.slice(1) // separa o segundo em diante
          console.log(data)
          this.loading = true
        })
    },
    thumbYoutube(id) {
      if (id != '') {
        return this.thumbYoutubeBaseUrl + id + this.thumbYoutubeJpg;
      }
      else {
        return 'https://i.ytimg.com/vi/jXC0i15mRns/sddefault.jpg'
      }
    }
  }
})
</script>

<template>
  <ion-page>
    <nav-bar-section section-title="Assista Nossos Cultos" back-button="/" />
    <ion-row id="filtro">
            <ion-col size="6">
              <ion-item>
                <ion-select v-model="selectedMonth" label="Mês" label-placement="floating" color="none"
                  @ionChange="loadEvents">
                  <ion-select-option value="01">Janeiro</ion-select-option>
                  <ion-select-option value="02">Fevereiro</ion-select-option>
                  <ion-select-option value="03">Março</ion-select-option>
                  <ion-select-option value="04">Abril</ion-select-option>
                  <ion-select-option value="05">Maio</ion-select-option>
                  <ion-select-option value="06">Junho</ion-select-option>
                  <ion-select-option value="07">Julho</ion-select-option>
                  <ion-select-option value="08">Agosto</ion-select-option>
                  <ion-select-option value="09">Setembro</ion-select-option>
                  <ion-select-option value="10">Outubro</ion-select-option>
                  <ion-select-option value="11">Novembro</ion-select-option>
                  <ion-select-option value="12">Dezembro</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
            <ion-col size="6">
              <ion-item>
                <ion-select v-model="selectedYear" label="Ano" label-placement="floating" color="none"
                  @ionChange="loadEvents">
                  <ion-select-option v-for="year in yearOptions" :key="year" :value="year"
                    :selected="year === currentYear">{{ year }}</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
    <ion-content id="main-content">
      <ion-grid>
        <div id="filter-container">          
        </div>
        <div class="skeleton-content" v-if="!loading">
          <ion-skeleton-text animated style="height: 200px;"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 300px;height: 20px;"></ion-skeleton-text>
          <ion-skeleton-text animated style="width:80px;"></ion-skeleton-text>
          <ion-skeleton-text animated style="width:150px;"></ion-skeleton-text>
          <ion-skeleton-text animated style="height: 200px;"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 300px;height: 20px;"></ion-skeleton-text>
          <ion-skeleton-text animated style="width:80px;"></ion-skeleton-text>
          <ion-skeleton-text animated style="width:150px;"></ion-skeleton-text>
        </div>
        <div v-if="loading">
          <ion-row>
            <ion-col id="list-culto-destaque" size="12" v-for="(item, index) in this.items1" :key="index">
              <ion-card :router-link="'/viewculto/' + item.cod_cultos">
                <ion-img :src="thumbYoutube(item.id_video)"></ion-img>
                <ion-card-header>
                  <ion-card-title v-html="item.tema_culto"></ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>{{ this.$formatDate(item.data_culto).dataCompleto }}<br>
                    {{ item.nome_tipo_culto }}</p>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col id="list-culto" size="6" v-for="(item, index) in this.items2" :key="index">
              <ion-card :router-link="'/viewculto/' + item.cod_cultos">
                <ion-img :src="thumbYoutube(item.id_video)"></ion-img>
                <ion-card-header>
                  <ion-card-title v-html="item.tema_culto"></ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <p>{{ formatDate(item.data_culto) }}<br>
                    {{ item.nome_tipo_culto }}</p>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>


<style scoped>
#filtro {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--ion-item-background);
}

ion-card.ios {
  margin: 10px;
}

#list-culto-destaque ion-card-title {
  font-size: 20px;
  font-weight: bold;
}

#list-culto ion-card-title {
  font-size: 14px;
  font-weight: bold;
}

/* #ibe-list-videos ion-card-content.ios {
  padding: 13px 16px;
}

#ibe-list-videos ion-card-header,
#ibe-list-videos ion-card-content.ios,
#ibe-list-videos ion-card-content.md {
  padding: 5px;
}



#ibe-list-videos ion-card-content p {
  font-size: 10px;
} */
</style>