<script lang="js">
import { defineComponent } from "vue";
import NavBarSection from "@/components/NavBarSection.vue";

import {
    IonPage,
    IonContent,
    IonCol,
    IonGrid,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonSkeletonText,
} from "@ionic/vue";
export default defineComponent({
    components: {
        IonPage,
        IonContent,
        IonCol,
        IonGrid,
        IonRow,
        NavBarSection,
        IonSelect,
        IonSelectOption,
        IonItem,
        IonSkeletonText,
    },
    data() {
        return {
            selectedMonth: '12',
            selectedYear: 2018,
            currentYear: null,
            loading: true,
            calendarItems: null,
            idTarget: null,
        }
    },
    computed: {
        yearOptions() {
            const currentYear = new Date().getFullYear();
            const startYear = currentYear - 1;
            const years = [];
            for (let year = currentYear + 1; year >= startYear; year--) {
                years.push(year);
            }
            return years;
        }
    },
    mounted() {
        this.selectedMonth = String(new Date().getMonth() + 1);
        this.selectedYear = new Date().getFullYear();
        this.loadEvents()
    },
    methods: {
        async loadEvents() {
            this.loading = false
            const urlParams = "?mes=" + this.selectedMonth + "&ano=" + this.selectedYear
            await fetch(this.$apiV1 + '/agenda/app' + urlParams)
                .then(Response => Response.json())
                .then(async data => {
                    this.loading = true
                    this.calendarItems = data
                    console.log(this.calendarItems)

                })
            for (let i = 0; i < this.calendarItems.length; i++) {
                //console.log(this.$formatDate(this.calendarItems[i].date).dataDefault)
                if (this.$formatDate(this.calendarItems[i].date).dataDefault >= new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })) {
                    //this.scrollToElement(this.$formatDate(this.calendarItems[i].date).dia)
                    this.idTarget = this.$formatDate(this.calendarItems[i].date).dia
                    break;
                }
            }
            this.scrollToElement(this.idTarget)
            this.idTarget = null
            

        },
        scrollToElement(idAlvo) {
            const elemento = document.getElementById(idAlvo);
            console.log(idAlvo)
            if (elemento) {
                elemento.scrollIntoView();
            }
        },
    }
});
</script>

<template>
    <ion-page @ionViewDidEnter="scrollToElement(idTarget)">
        <nav-bar-section section-title="Agenda" back-button="/" />
        <ion-row id="filtro">
            <ion-col size="6">
                <ion-item>
                    <ion-select v-model="selectedMonth" label="Mês" label-placement="floating" color="none"
                        @ionChange="loadEvents">
                        <ion-select-option value="01">Janeiro</ion-select-option>
                        <ion-select-option value="02">Fevereiro</ion-select-option>
                        <ion-select-option value="03">Março</ion-select-option>
                        <ion-select-option value="04">Abril</ion-select-option>
                        <ion-select-option value="05">Maio</ion-select-option>
                        <ion-select-option value="06">Junho</ion-select-option>
                        <ion-select-option value="07">Julho</ion-select-option>
                        <ion-select-option value="08">Agosto</ion-select-option>
                        <ion-select-option value="09">Setembro</ion-select-option>
                        <ion-select-option value="10">Outubro</ion-select-option>
                        <ion-select-option value="11">Novembro</ion-select-option>
                        <ion-select-option value="12">Dezembro</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col size="6">
                <ion-item>
                    <ion-select v-model="selectedYear" label="Ano" label-placement="floating" color="none"
                        @ionChange="loadEvents">
                        <ion-select-option v-for="year in yearOptions" :key="year" :value="year"
                            :selected="year === currentYear">{{ year }}</ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-content id="main-content">
            <ion-grid>
                <div class="skeleton-content" v-show="!loading">
                    <ion-row>
                        <ion-col size="2"><ion-skeleton-text animated style="height: 40px; right: 0;"></ion-skeleton-text></ion-col>
                        <ion-col size="10"><ion-skeleton-text animated style="height: 80px; right: 0;"></ion-skeleton-text></ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="10" offset="2"><ion-skeleton-text animated style="height: 80px; right: 0;"></ion-skeleton-text></ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size="2"><ion-skeleton-text animated style="height: 40px; right: 0;"></ion-skeleton-text></ion-col>
                        <ion-col size="10"><ion-skeleton-text animated style="height: 80px; right: 0;"></ion-skeleton-text></ion-col>
                    </ion-row>
                </div>
                <ion-row v-show="loading">
                    <ion-col>
                        <ul class="agenda" v-for="(item, index) in calendarItems" :key="index">
                            <li :id="$formatDate(item.date).dia">
                                <span class="agenda-item-data semana">{{ $formatDate(item.date).diaSemana }}</span>
                                <span class="agenda-item-data dia">{{ $formatDate(item.date).dia }}</span>
                                <ul class="agenda-item" v-for="(subItem, subIndex) in item.items" :key="subIndex"
                                    style="background-color: #971a1d; color: white">
                                    <li>
                                        <p>{{ subItem.titulo }}</p>
                                        <p class="agenda-subitem">
                                            {{ subItem.start.dateTime ? $formatDate(subItem.start.dateTime).hora : "" }} {{ subItem.end.dateTime ? $formatDate(subItem.end.dateTime).hora : "" }}</p>
                                        <p class="agenda-subitem">{{ subItem.ministerio }} | {{ subItem.departamento }}</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>


<style scoped>

.agenda {
    border-top: 1px solid #971a1d;
    position: relative;
    min-height: 50px;
    display: table;
    width: 100%;
}

.agenda ul {
    padding: 0;
}

.agenda li {
    padding-left: 15px;
    padding-right: 15px;
    display: table-cell;
    vertical-align: middle;
}

.agenda-item {
    position: relative;
    margin-top: 10px;
    border-radius: 8px;
    min-height: 70px;
    border: 0;
}

.agenda-item li {
    width: 100%;
    padding: 5px;
}

.agenda-item p {
    margin: 0;
    padding-left: 5px;
    font-size: 0.9rem;
}

.agenda-item-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

p.agenda-subitem {
    font-size: 0.8rem;
}

p.agenda-subitem.ebd {
    padding-left: 15px;
}

.agenda-item p .material-icons {
    padding-right: 5px;
    font-size: 0.5rem;
}

.agenda-item-data {
    position: absolute;
    width: 50px;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    text-align: center;
    color: #971a1d;
    padding: 12px 0;
    font-size: 12px;
}

.agenda-item-data.dia {
    top: 12px;
    font-size: 22px;
}

.agenda-item-data.semana {
    top: 0;
    font-size: 13px;
    letter-spacing: -1px;
}

.agenda-item-data.mes {
    top: 36px;
    font-size: 15px;
    letter-spacing: -1px;
}
</style>