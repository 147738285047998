<template>
    <div id="ibe-navbar">
      <ion-header :translucent="false">
        <ion-toolbar color="custom1">
          <ion-buttons slot="start">
            <ion-back-button :default-href="back"></ion-back-button>
          </ion-buttons>
          <ion-title>{{ title }}</ion-title>
        </ion-toolbar>
      </ion-header>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  
} from '@ionic/vue';
export default defineComponent({
  name: 'NavBarSection',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonBackButton,
    
  },
  props: {
    sectionTitle: String,
    backButton: String
  },
  data() {
    return {
        title: this.sectionTitle,
        back: this.backButton
    }
  }
})

</script>

<style scoped>

</style>