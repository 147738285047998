<script lang="js">
import { defineComponent } from "vue";
import NavBarSection from "@/components/NavBarSection.vue";
import {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonSkeletonText,
} from "@ionic/vue";
export default defineComponent({
    components: {
        IonPage,
        IonContent,
        NavBarSection,
        IonGrid,
        IonRow,
        IonCol,
        IonCard,
        IonCardContent,
        IonSkeletonText,
    },
    data() {
        return {
            infoCulto: '',
            thumbYoutubeBaseUrl: 'https://img.youtube.com/vi/',
            thumbYoutubeJpg: '/mqdefault.jpg',
            iframe: '',
            loading: false
        }
    },
    mounted() {
        this.loadEvents();
    },
    methods: {
        async loadEvents() {
            await fetch(this.$apiV1 + '/cultos/' + this.$route.params.id)
                .then(Response => Response.json())
                .then(async data => {
                    this.infoCulto = data
                    console.log(this.infoCulto)
                    this.iframe = '<iframe src="https://www.youtube.com/embed/' + this.infoCulto.id_video + '?autoplay=0" frameborder="0" allowfullscreen="" scrolling="no" style="position: absolute; top: 0;left: 0;width: 100%;height: 100%"></iframe>'
                    this.loading = true
                })
        },
        formatDate(e) {
            const date = new Date(e);
            return date.getDate().toString().padStart(2, '0') + '/' + date.getMonth().toString().padStart(2, '0') + '/' + date.getFullYear()
        }
    }
})
</script>

<template>
    <ion-page>
        <nav-bar-section section-title="Assista Nossos Cultos" back-button="/ListCultos" />
        <ion-content id="main-content">

            <div class="skeleton-content" v-if="!loading">
                <ion-skeleton-text animated style="height: 200px;"></ion-skeleton-text>
                <p><ion-skeleton-text animated style="width: 300px;height: 20px;"></ion-skeleton-text></p>
                <p><ion-skeleton-text animated style="width:80px;"></ion-skeleton-text></p>
                <p><ion-skeleton-text animated style="width:150px;"></ion-skeleton-text></p>
            </div>

            <div v-if="loading">
                <div class="video-container">
                    <div v-html="iframe"></div>
                </div>
                <!-- <iframe :src="'https://www.youtube.com/embed/' + this.infoCulto.id_video + '?autoplay=0'" frameborder="0" allowfullscreen="" scrolling="no"></iframe> -->
                <ion-grid>
                    <ion-row>
                        <ion-col>
                            <h1 v-html="infoCulto.tema_culto"></h1>
                            <p>{{ infoCulto.nome_pregador }}</p>
                            <p>{{ formatDate(infoCulto.data_culto) }}</p>
                        </ion-col>
                    </ion-row>
                </ion-grid>
                <ion-card>
                    <ion-card-content>
                        <ion-row>
                            <ion-col>
                                <span v-html="infoCulto.detalhe_culto">
                                </span>
                            </ion-col>
                        </ion-row>
                    </ion-card-content>
                </ion-card>
            </div>
        </ion-content>
    </ion-page>
</template>


<style scoped>

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

#ibe-page-banner ion-card {
    background-image: url("/src/img/button(1).jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0;
}

#ibe-page-banner ion-card-content {
    height: 100%;
    background: rgba(240, 90, 40, .3);
    color: white;
    text-align: center;
}

#ibe-page-banner ion-card-content p {
    color: white;
    text-shadow: 0 0 0 #2a2a2a;
    font-family: gobold;
    font-size: 1.3rem;
}

ion-card {
    margin: 10px;
}

ion-card-content {
    padding: 10px;
}

/* #ibe-list-videos ion-card-content.ios {
  padding: 13px 16px;
}

#ibe-list-videos ion-card-header,
#ibe-list-videos ion-card-content.ios,
#ibe-list-videos ion-card-content.md {
  padding: 5px;
}

#ibe-list-videos ion-card-title {
  font-size: 10px;
  font-weight: bold;
}

#ibe-list-videos ion-card-content p {
  font-size: 10px;
} */
</style>