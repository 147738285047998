import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';

const app = createApp(App)
  .use(IonicVue)
  .use(router);

// configurações Global do APP no arquivo Custom.ts

import { apiV1, formatDate, User  } from './custom';
app.config.globalProperties.$apiV1 = apiV1;
app.config.globalProperties.$user = User;
app.config.globalProperties.$formatDate = formatDate;

router.isReady().then(() => {
  const vm = app.mount('#app');
});
