<script lang="js">
import { mask } from '@ionited/mask-vue';

import { defineComponent } from "vue";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonItem,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonInput,
  alertController,
  IonSpinner,
  IonList
} from "@ionic/vue";
import NavBarSection from "@/components/NavBarSection.vue";
export default defineComponent({
  directives: { mask },
  components: {
    IonPage,
    IonContent,
    IonCol,
    IonGrid,
    IonRow,
    IonItem,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    NavBarSection,
    IonButton,
    IonInput,
    IonSpinner,
    IonList
  },
  data() {
    return {
      motivo: '',
      mensagem: '',
      loading: false
    };
  },
  methods: {
    async enviaPedido(e) {
      this.loading = true
      console.log('Iniciando chamada API...')
      var user = JSON.parse(localStorage.user)
      e.preventDefault();
      e.target.disabled = true;
      const controller = new AbortController()
      setTimeout(() => controller.abort(), 4000)
      await fetch(this.$apiV1 + '/oracao/addApp', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        signal: controller.signal,
        body: JSON.stringify({
          assunto: this.motivo,
          mensagem: this.mensagem,
          cod_usuarios: user.cod_usuarios
        })
      })
        .then(Response => Response.json())
        .then(async data => {
          console.log(data)
          if (data == null) {
            const alert = await alertController.create({
              header: 'Ops, tivemos um problema. Tente mais tarde',
              //subHeader: 'tente novamente',
              //message: 'This is an alert!',
              buttons: ['OK'],
            });
            await alert.present();
          }
          else if (data.error > 0) {
            const alert = await alertController.create({
              //header: data.msg,
              subHeader: data.msg,
              //message: 'This is an alert!',
              buttons: ['OK'],
            });
            await alert.present();
            this.motivo = ''
          }
          else {
            const alert = await alertController.create({
              //header: 'data.msg',
              subHeader: 'Recebemos seu pedido',
              //message: 'vamos orar pelo seu pedido',
              buttons: [{
                text: 'OK',
                handler: () => {
                  this.$router.replace('/home');
                }
              }],
            });
            await alert.present();
          }
        })

        .catch(async error => {
          console.error('timeout excedido');
          const alert = await alertController.create({
            header: 'Ops, tivemos um problema. Tente mais tarde',
            //subHeader: 'tente novamente',
            //message: 'This is an alert!',
            buttons: ['OK'],
          });
          await alert.present();
        })

      this.loading = false

    }
  }

});
</script>

<template>
  <ion-page>
    <nav-bar-section section-title="Pedido de Oração" back-button="/" />
    <ion-content id="main-content">
      <ion-grid>
        <form id="ibe-form-pedido-oracao" action="#" @submit="enviaPedido($event)">
          <ion-list>
            <ion-item class="ion-hide">
              <ion-input label="Telefone" label-placement="floating" fill="outline"
                v-mask="{ mask: '(99) 99999-9999' }" />
            </ion-item>
            <ion-item>
              <ion-select v-model="motivo" label="Qual motivo da oração?" label-placement="floating" color="none">
                <ion-select-option value="item 1">Saúde</ion-select-option>
                <ion-select-option value="item 2">Relacionamento</ion-select-option>
                <ion-select-option value="item 3">Agradecimento</ion-select-option>
                <ion-select-option value="item 4">Financeiro</ion-select-option>
                <ion-select-option value="item 5">Trabalho</ion-select-option>
                <ion-select-option value="item 6">Estudo</ion-select-option>
                <ion-select-option value="item 7">Outros</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-textarea class="custom" v-model="mensagem" :counter="true" rows="5" maxlength="200" label="Pedido de Oração"
              label-placement="floating" color="none" ></ion-textarea>
          </ion-list>
          <ion-row>
            <ion-col>
              <ion-button shape="round" expand="block" color="warning" type="submit" :disabled="loading">
                <span>Enviar Pedido</span>
                <ion-spinner v-if="loading"></ion-spinner>
              </ion-button>
            </ion-col>
          </ion-row>
        </form>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>



<style scoped>
#ibe-page-banner ion-card {
  background-image: url("/src/img/button(1).jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0;
}

#ibe-page-banner ion-card-content {
  height: 100%;
  background: rgba(240, 90, 40, .3);
  color: white;
  text-align: center;
}

#ibe-page-banner ion-card-content p {
  color: white;
  text-shadow: 0px 0px 0px #2a2a2a;
  font-family: gobold;
  font-size: 1.3rem;
}
ion-button {
  font-family: gobold;
  --ion-color-contrast: white !important;
}
ion-textarea.custom {
  padding-left: 16px;
}
</style>