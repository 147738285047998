import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import MyHome from '@/views/MyHome.vue';
import LogIn from "@/views/LogIn.vue";
import PedidoOracao from "@/views/PedidoOracao.vue"
import ListCultos from '@/views/ListCultos.vue'
import ListAgenda from '@/views/ListAgenda.vue';
//import EscolaBiblica from '@/views/EscolaBiblica.vue';
//import EscolaBiblicaTurma from '@/views/EscolaBiblicaTurma.vue';
import TesteApp from '@/views/TesteApp.vue';
import NewUser from '@/views/NewUser.vue';
import ViewCulto from '@/views/ViewCulto.vue';


const routes: Array<RouteRecordRaw> = [
/*   {
    path: '/teste',
    name: 'Teste',
    component: TesteApp,
  }, 
  {
    path: '/boletim', // Esse código redireciona para o site externo. Lembrar de criar um compenent vazio e ver se funciona.
    component: TesteApp,
    beforeEnter() {
      window.location.href = "https://boletim.ibesp.org.br";
    }
  }, */
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: MyHome
  },
  {
    path: '/login',
    name: 'Login',
    component: LogIn,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/cadastro',
    name: 'Cadastro',
    component: NewUser,
    meta: {
      requireAuth: false
    }
  },
  {
    path: '/agenda',
    name: 'ListAgenda',
    component: ListAgenda
  },
  {
    path: '/pedido-de-oracao',
    name: 'PedidoOracao',
    component: PedidoOracao
  },
  {
    path: '/listcultos',
    name: 'ListCultos',
    component: ListCultos,
  },
  {
    path: '/viewculto/:id',
    name: 'ViewCulto',
    component: ViewCulto
  },
  {
    path: '/ebd',
    component: () => import('@/components/NavBarFooter.vue'),
    children: [
      {
        path: 'lista-cursos',
        name: 'ListaCursos',
        component: () => import('@/views/MeusCursos.vue'),
      },
      {
        path: 'detalhes-curso/:id',
        name: 'DetalhesCurso',
        component: () => import('@/views/ListCursos.vue'),
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),  
  routes,
})

router.beforeEach(async (to, from) => {
  //console.log(to.meta.requireAuth)
  if (localStorage['login'] === 'true' && to.meta.requireAuth === false) {
    console.log('redirecionar para home')
    return { name: 'Home' }
  }
  else if (typeof to.meta.requireAuth === 'undefined' && localStorage['login'] !== 'true') {
    console.log('redirecionar para login')
    return { name: 'Login' }
  }
})

export default router