<script lang="js">
import { defineComponent } from "vue";

import {
  IonPage,
  IonContent,
  IonCol,
  IonGrid,
  IonRow,
  IonInput,
  IonButton,
  IonItem,
  IonText,
  alertController,
  IonSpinner,
} from "@ionic/vue";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonCol,
    IonGrid,
    IonRow,
    IonInput,
    IonButton,
    IonItem,
    IonText,
    IonSpinner,
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false
    }
  },
  mounted() {
    console.log(this.$apiV1)
  },
  methods: {
    async login(e) {
      this.loading = true
      console.log('Iniciando chamada API...')
      e.preventDefault();
      const controller = new AbortController()
      setTimeout(() => controller.abort(), 4000)
      await fetch(this.$apiV1 + '/autenticacao/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        signal: controller.signal,
        body: JSON.stringify({
          email: this.email,
          senha: this.password
        })
      })
        .then(Response => Response.json())

        .then(async data => {
          console.log(data)
          if (data == null) {
            const alert = await alertController.create({
              header: 'Ops, tivemos um problema. Tente mais tarde',
              //subHeader: 'tente novamente',
              //message: 'This is an alert!',
              buttons: ['OK'],
            });
            await alert.present();
          }
          else if (data.error > 0) {
            const alert = await alertController.create({
              header: data.msg,
              //subHeader: 'tente novamente',
              //message: 'This is an alert!',
              buttons: ['OK'],
            });
            await alert.present();
          }
          else {
            localStorage['user'] = JSON.stringify(data.user)
            localStorage['login'] = true
            this.$router.replace('/home');
          }
        })

        .catch(async error => {
          console.error('timeout excedido');
          const alert = await alertController.create({
            header: 'Ops, tivemos um problema. Tente mais tarde',
            //subHeader: 'tente novamente',
            //message: 'This is an alert!',
            buttons: ['OK'],
          });
          await alert.present();
        })

      this.loading = false

    }
  }
})
</script>




<template>
  <ion-page id="main-content">
    <ion-content>
      <div id="content">
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col size="5">
              <img src="/assets/logo/logo.png" alt="">
            </ion-col>
          </ion-row>
          <form id="formLogin" action="#" @submit="login($event)">
            <ion-row class="ion-justify-content-center">
              <ion-col size="11">
                <ion-item lines="none">
                  <img src="/assets/icon/person.svg" alt="">
                  <ion-input v-model="email" placeholder="e-mail" class="custom"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size="11">
                <ion-item lines="none">
                  <img src="/assets/icon/lock-closed.svg" alt="">
                  <ion-input v-model="password" placeholder="senha" class="custom" type="password"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size="11">
                <ion-button shape="round" expand="block" color="warning" type="submit">
                  <ion-spinner v-if="loading"></ion-spinner>
                  <span v-if="!loading">entrar</span>
                </ion-button>
                <ion-text>
                  <p class="ion-text-center">Esqueceu sua senha?</p>
                  <p class="ion-text-center"><router-link to="/cadastro">Novo Cadastro</router-link></p>
                </ion-text>
              </ion-col>
            </ion-row>
          </form>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>




<style scoped>
#content {
  position: absolute;
  top: 15%;
}

a {
  text-decoration: none;
  color: inherit
}

p {
  margin: 20px;
  font-family: gotham;
  opacity: .5;
}

ion-item {
  position: relative;
}

ion-item img {
  left: 10px;
  width: 25px;
  position: absolute;
  z-index: 1000;
  opacity: .5;
}

ion-icon {
  position: absolute;
}

ion-button {
  padding: 0 10px 0 15px;
  font-family: gobold;
  --ion-color-contrast: white !important;
}

ion-input.custom {
  --background: #eeeeee;
  --color: #525252;
  border-radius: 25px !important;
  --padding-bottom: 15px !important;
  --padding-end: 20px !important;
  --padding-start: 40px !important;
  --padding-top: 15px !important;
}
</style>