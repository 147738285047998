export const apiV1 = 'https://api-qa.ibesp.org.br/portalibesp/v1';

export const User = 'user' in localStorage ? JSON.parse(localStorage['user']) : null

export const formatDate = function (dataHora: string) {
    if (dataHora.indexOf(' ') !== -1 ) {
        dataHora = dataHora.replace(' ', 'T');
    }
    if (dataHora.indexOf('T') === -1) {
        dataHora += 'T00:00';
    }
    //console.log(dataHora)
    const date = new Date(dataHora);
  
    const diaSemanaNomes = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];
    const diaSemanaNomesCompleto = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
    const mesNomes = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"]
    //const day = (date.getDate()).toString().padStart(2, '0');
    const day = date.toLocaleDateString('pt-BR', {day: "2-digit"})
    const dataCompleto = date.toLocaleDateString('pt-BR', { day: "2-digit", month: "2-digit", year: "numeric"})
    const month = mesNomes[date.getMonth()];
    const monthNumber = (date.getMonth() + 1).toString().padStart(2, '0');
    const diaSemana = diaSemanaNomes[(date.getDay())];
    const diaSemanaCompleto = diaSemanaNomesCompleto[date.getDay()];
    const year = date.getFullYear();
    //const dataCompleto = `${day}/${monthNumber}/${year}`
    const hora = date.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit", second: undefined })
    return {
        dataDefault: date.toISOString().slice(0, 10),
        dataCompleto: dataCompleto,
        dia: day,
        mes: monthNumber,
        mesString: month,
        ano: year,
        diaSemana: diaSemana,
        diaSemanaCompleto: diaSemanaCompleto,
        hora: hora
    }
  }