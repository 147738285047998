<template>
  <ion-page>
    <nav-bar></nav-bar>
    
    <ion-content id="main-content">
      <my-carousel></my-carousel>
      <ion-grid>
        <div id="ibe-button-section">
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-card router-link="/ListCultos" router-direction="back" 
              style="background-image: url('');
               background-repeat:no-repeat; 
               background-size: cover; 
               background-position: center; 
               margin: 0;">
                <ion-card-content 
                style="background: rgba(188, 0, 0, 1);" 
                >
                  <ion-grid>
                    <ion-row class="ion-align-items-center">
                      <ion-col>
                        <p>VÍDEOS</p>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size="6">
              <ion-card router-link="/agenda" router-direction="back" 
              style="background-image: url('');
               background-repeat:no-repeat; 
               background-size: cover; 
               background-position: center; 
               margin: 0;">
                <ion-card-content
                style="background: rgba(188, 0, 0, 1);" 
                >
                  <ion-grid>
                    <ion-row class="ion-align-items-center">
                      <ion-col>
                        <p>AGENDA</p>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-card router-link="/pedido-de-oracao" router-direction="back" 
              style="background-image: url('');
               background-repeat:no-repeat; 
               background-size: cover; 
               background-position: center; 
               margin: 0;">
                <ion-card-content
                style="background: rgba(220, 0, 0, 1);" 
                >
                  <ion-grid>
                    <ion-row class="ion-align-items-center">
                      <ion-col>
                        <p>ORAÇÕES</p>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size="6">
              <ion-card router-link="/ebd/lista-cursos" router-direction="back" 
              style="background-image: url('');
               background-repeat:no-repeat; 
               background-size: cover; 
               background-position: center; 
               margin: 0;">
                <ion-card-content
                style="background: rgba(220, 0, 0, 1);" 
                >
                  <ion-grid>
                    <ion-row class="ion-align-items-center">
                      <ion-col>
                        <p>ESCOLA BÍBLICA</p>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-center">
            <ion-col size="6">
              <ion-card router-link="#" router-direction="back" 
              style="background-image: url('');
               background-repeat:no-repeat; 
               background-size: cover; 
               background-position: center; 
               margin: 0;">
                <ion-card-content
                style="background: rgba(255, 0, 0, 1);" 
                >
                  <ion-grid>
                    <ion-row class="ion-align-items-center">
                      <ion-col>
                        <p>CONTRIBUIÇÃO</p>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size="6">
              <ion-card router-link="#" router-direction="back" 
              style="background-image: url('');
               background-repeat:no-repeat; 
               background-size: cover; 
               background-position: center; 
               margin: 0;">
                <ion-card-content
                style="background: rgba(255, 0, 0, 1);" 
                >
                  <ion-grid>
                    <ion-row class="ion-align-items-center">
                      <ion-col>
                        <p>EBKIDS</p>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import NavBar from "@/components/NavBar.vue";
import MyCarousel from "@/components/MyCarousel.vue";

import {
    IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonRow,
} from "@ionic/vue";
export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonRow,
    NavBar,
    MyCarousel
  }
});
</script>

<style scoped>


#ibe-button-section ion-card-content {
    height: 100%;
    color: white;
    text-align: center;
    padding: 0;
  }
#ibe-button-section ion-card-content ion-grid {
    padding: 40px 0;
  }
  #ibe-button-section ion-card-content p {
    color: white;
    text-shadow: 0px 0px 0px #2a2a2a;
    font-family: gobold;
    font-size: 1.3rem;
  }
a {
    text-decoration: none;
  }
</style>