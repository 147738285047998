<template>
    <div id="ibe-navbar">
      <ion-menu content-id="main-content">
        <ion-header :translucent="true">
          <ion-toolbar class="ibe-menu-header">
            <ion-row class="ion-justify-content-center">
              <ion-col size="3">
                <ion-avatar>
                  <img :src="avatar" />
                </ion-avatar>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="ion-text-center">
                <p class="ion-no-margin ibe-text-avatar">
                  {{ user['nome_usuario'] }}
                </p>
              </ion-col>
            </ion-row>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-list role="list">
            <ion-menu-toggle>
              <ion-item lines="none" router-link="/home" router-direction="root">
                <ion-icon size="" slot="start" ios="/assets/icon/home-outline.svg" md="/assets/icon/home.svg"></ion-icon>
                <ion-label>Início</ion-label>
              </ion-item>
              <ion-item lines="none" router-link="/pedido-de-oracao" router-direction="root">
                <ion-icon slot="start" ios="/assets/icon/person-outline.svg" md="/assets/icon/person.svg"></ion-icon>
                <ion-label>Perfil</ion-label>
              </ion-item>
              <ion-item lines="full" router-link="/listcultos" router-direction="root">
                <ion-icon slot="start" ios="/assets/icon/chatbox-outline.svg" md="/assets/icon/chatbox.svg"></ion-icon>
                <ion-label>Enviar Feedback</ion-label>
              </ion-item>
              <ion-item lines="none" @click='logout'>
                <ion-icon slot="start" ios="/assets/icon/log-out-outline.svg" md="/assets/icon/log-out.svg"></ion-icon>
                <ion-label>Desconectar</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
      </ion-menu>

      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-title>
            <img src="../img/logo-abrev.png" alt="logo-ibe" id="ibe-nav-logo" />
          </ion-title>
          <ion-buttons slot="end">
            <ion-menu-button>
              <img :src="avatar" />
            </ion-menu-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
    </div>
</template>

<script lang="js">
import { defineComponent } from 'vue';
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonAvatar,
  IonList,
  IonMenuToggle,
  IonItem,
  IonRow,
  IonCol,
  IonIcon,
  IonLabel,
} from '@ionic/vue';
export default defineComponent({
  name: 'NavBar',
  components: {
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonMenuButton,
    IonAvatar,
    IonList,
    IonMenuToggle,
    IonItem,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    
  },
  data() {
    return {
      avatar: '/assets/user.png',
      user: ''
    }
  },
  mounted() {
    if (localStorage['login']){
      this.user = JSON.parse(localStorage['user']),
      this.avatar = this.user.imagem
    }
    console.log(this.$user)
  },
  methods: {
    async logout() {
      localStorage.clear()
      this.$router.replace('/login');
    }
  }
})

</script>

<style scoped>
  ion-menu::part(container) {
    border-radius: 0 20px 20px 0;
    box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.18);
  }
  .ibe-menu-header {
    --min-height: 100px;
    background-image: url("@/img/login-bg-7.jpg");
    --opacity: .0
  }
  .ibe-text-avatar {
    color: white;
    text-shadow: 1px 1px 2px #2a2a2a;
  }
  ion-menu-button img {
    border-radius: 20px;
    width: 30px;
  }
  #ibe-nav-logo {
    width: 40px;
  }
  ion-title {
    left: 0px;
    top: 0px;
    padding-left: 90px;
    padding-right: 90px;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateZ(0px);
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    box-sizing: border-box;
    pointer-events: none;
  }
</style>